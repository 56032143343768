import React, { useCallback, useMemo, useState } from "react";
import { useLightbox } from "simple-react-lightbox-pro";

import PortfolioLightbox from "./PortfolioLightbox";

const Portfolio = ({ data }) => {
  const { openLightbox, closeLightbox } = useLightbox();
  const [selectedProject, setSelectedProject] = useState(undefined);

  const onHoverProject = useCallback(
    (project) => {
      setSelectedProject(project);
    },
    [setSelectedProject]
  );

  const onCloseLightbox = useCallback(() => {
    closeLightbox();
    setSelectedProject(undefined);
  }, [setSelectedProject, closeLightbox]);

  const projects = useMemo(() => {
    if (!data?.projects) {
      return [];
    }
    return data.projects.map(function (project) {
      var projectImage = "images/portfolio/" + project.image;
      return (
        <div
          key={project.title}
          className="columns portfolio-item"
          onMouseOver={() => onHoverProject(project)}
          onClick={openLightbox}
        >
          <div
            className="item-wrap portfolio-custom-style"
            style={{ height: "140px", display: "flex", alignItems: "center" }}
          >
            <img alt={project.title} src={projectImage} />
            <div className="overlay">
              <div className="portfolio-item-meta">
                <h5>{project.title}</h5>
              </div>
            </div>
          </div>
        </div>
      );
    });
  }, [data, onHoverProject, openLightbox]);

  return (
    <section id="portfolio">
      <div className="row">
        <div className="twelve columns collapsed">
          <h1>Check Out Some of My Works.</h1>
          <div
            id="portfolio-wrapper"
            className="bgrid-quarters s-bgrid-thirds cf"
          >
            {projects}
          </div>
          {selectedProject && (
            <PortfolioLightbox
              project={selectedProject}
              onClose={onCloseLightbox}
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
