import React, { useMemo } from "react";
import { SRLWrapper } from "simple-react-lightbox-pro";
import ReactMarkdown from "react-markdown";

const PortfolioLightbox = ({ project, onClose }) => {
  var items = useMemo(() => {
    if (!project?.items) {
      return [];
    }
    return project.items.map((item) => {
      const { title, type, image, src } = item;
      var itemImage = "images/portfolio/" + image;
      if (type === "video") {
        // return null;
        return (
          <a
            key={title}
            srl_video_thumbnail={itemImage}
            srl_video_caption={title}
            srl_video_scale="100"
            srl_video_width="100%"
            href={src}
          >
            <img alt={title} src={itemImage} />
          </a>
        );
      }
      return <img key={title} alt={title} src={itemImage} />;
    });
  }, [project]);

  const customCaptions = useMemo(() => {
    if (!project?.items) {
      return [];
    }
    return project.items.map((item, idx) => {
      const caption = (
        <div className="lightbox-caption">
          <ReactMarkdown>{`**${item.title}** ${item.description}`}</ReactMarkdown>
        </div>
      );
      return { id: idx, caption };
    });
  }, [project]);

  if (!items || items.length === 0) {
    return null;
  }

  return (
    <div style={{ display: "none" }}>
      <SRLWrapper
        key={project.title}
        callbacks={{ onLightboxClosed: onClose }}
        customCaptions={customCaptions}
      >
        {items}
      </SRLWrapper>
    </div>
  );
};

export default PortfolioLightbox;
