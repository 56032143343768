import React, { Component } from "react";
import ReactGA from "react-ga";
import SimpleReactLightbox from "simple-react-lightbox-pro";

import "./App.css";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import About from "./Components/About";
import Resume from "./Components/Resume";
import Portfolio from "./Components/Portfolio";

// data
import resumeData from "./resumeData";

class App extends Component {
  constructor(props) {
    super(props);
    ReactGA.initialize("UA-45401535-1");
    ReactGA.pageview(window.location.pathname);
  }

  render() {
    return (
      <SimpleReactLightbox>
        <div className="App">
          <Header data={resumeData.main} />
          <About data={resumeData.main} />
          <Resume data={resumeData.resume} />
          <Portfolio data={resumeData.portfolio} />
          <Footer data={resumeData.main} />
        </div>
      </SimpleReactLightbox>
    );
  }
}

export default App;
