import React, { useMemo } from "react";
import ReactMarkdown from "react-markdown";

const Resume = ({ data }) => {
  var { skillmessage } = data;

  const education = useMemo(() => {
    return data.education.map((education) => (
      <div key={education.school}>
        <h3>{education.school}</h3>
        <p className="info">
          {education.degree} <span>&bull;</span>
          <em className="date">{education.graduated}</em>
        </p>
        <p>{education.description}</p>
      </div>
    ));
  }, [data.education]);

  const work = useMemo(() => {
    return data.work.map((work) => (
      <div key={work.company}>
        <h3>{work.company}</h3>
        <p className="info">
          {work.title}
          <span>&bull;</span> <em className="date">{work.years}</em>
        </p>
        <ReactMarkdown>{work.description}</ReactMarkdown>
      </div>
    ));
  }, [data.work]);

  const skills = useMemo(() => {
    return data.skills.map((skill) => {
      var className = "bar-expand " + skill.name.toLowerCase();
      return (
        <li key={skill.name}>
          <span style={{ width: skill.level }} className={className}></span>
          <em>{skill.name}</em>
        </li>
      );
    });
  }, [data.skills]);

  const publications = useMemo(() => {
    return data.publications.map((pub) => (
      <li key={pub.title}>{`${pub.authors}. ${pub.title} in ${pub.venue}.`}</li>
    ));
  }, [data.publications]);

  return (
    <section id="resume">
      <div className="row education">
        <div className="three columns header-col">
          <h1>
            <span>Education</span>
          </h1>
        </div>

        <div className="nine columns main-col">
          <div className="row item">
            <div className="twelve columns">{education}</div>
          </div>
        </div>
      </div>

      <div className="row work">
        <div className="three columns header-col">
          <h1>
            <span>Work</span>
          </h1>
        </div>

        <div className="nine columns main-col">{work}</div>
      </div>

      <div className="row publications">
        <div className="three columns header-col">
          <h1>
            <span>Publications</span>
          </h1>
        </div>

        <div className="nine columns main-col">
          <ul className="skills">{publications}</ul>
        </div>
      </div>

      <div className="row skill">
        <div className="three columns header-col">
          <h1>
            <span>Skills</span>
          </h1>
        </div>

        <div className="nine columns main-col">
          <p>{skillmessage}</p>

          <div className="bars">
            <ul className="skills">{skills}</ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Resume;
