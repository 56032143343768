export default {
  main: {
    name: "Javid Hsueh",
    occupation: "Data Visualization Engineer",
    description:
      "Javid is a senior data visualization engineer at Uber. At Uber, he builds exploratory data visualizations to get actionable insights and contribute efforts to open-source projects.",
    image: "profilepic.png",
    bio:
      "Javid Chien-Hsin Hsueh, received his Master degree in Computer Science at UC Davis under Prof. Kwan-Liu Ma in the VIDI (Visualization and Interface Design Innovation) research group. His research interests include information visualization, visual analytics, and user interface design. Now Javid is a visualization engineer at Uber. He mostly works on machine learning vis, graph vis, and charting/dashboarding tools for helping internal users to find business insights.",
    contactmessage:
      "Here is where you should write your message to readers to have them get in contact with you.",
    email: "javidhsueh@gmail.com",
    phone: "530-601-3761",
    website: "https://javid.me/",
    resumedownload: "/resume.pdf",
    social: [
      {
        name: "facebook",
        url: "https://facebook.com/javid.hsueh/",
        className: "fa fa-facebook",
      },
      {
        name: "twitter",
        url: "https://twitter.com/javidhsueh",
        className: "fa fa-twitter",
      },
      {
        name: "linkedin",
        url: "https://www.linkedin.com/in/javid-hsueh/",
        className: "fa fa-linkedin",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/javidhsueh",
        className: "fa fa-instagram",
      },
      {
        name: "github",
        url: "https://github.com/javidhsueh",
        className: "fa fa-github",
      },
    ],
  },
  resume: {
    skillmessage:
      "Strong knowledge of JavaScript especially using React including advanced concepts like hooks, context and high order components and often using GraphQL and Apollo.",
    education: [
      {
        school: "University of California, Davis",
        degree: "Master in Computer Science",
        graduated: "Feb 2016",
        description:
          "Working with Prof. Kwan-Liu Ma, in Visualization Interface and Design Innovation laboratory.",
      },
    ],
    work: [
      {
        company: "Uber",
        title: "Senior Software Engineer",
        years: "Feb 2016 - Present",
        description: `
  * Leading efforts to build machine learning visualization and the next generation of machine learning platform ['Michelangelo'](https://eng.uber.com/michelangelo-machine-learning-platform/).
  * Uber knowledge graph visualization tool which helps Risk & Safety team to investigate frauds.
  * Published open source graph drawing library [Graph.gl](https://graph.gl)
  * Self-service dashboarding tools, BI visual analytic tools, and A/B testing platform.
  * Lecturer for data visualization in Graduate school of management, UC Davis.
        `,
      },
      {
        company: "Exploratorium",
        title: "Exhibit developer / Resident scientist",
        years: "July 2014 - February 2016",
        description: `
  * Designed and implemented [interactive visualization](https://www.youtube.com/watch?v=psGvUuYxEbM) of [TOPP](http://www.coml.org/projects/tagging-pacific-predators-topp.html) marine animal movement data sets using Openframeworks. 
  * Developed an [exhibit](https://www.exploratorium.edu/exhibits/mapping-migrations) showing the migration of marine animals using a tangible object and touchscreen interface.",
  * Published "Fostering Comparisons: Designing an Interactive Exhibit that Visualizes Marine Animal Behaviors." in IEEE Pacific Visualization Symposium, 2015. 
          `,
      },
      {
        company: "UC Davis",
        title: "Graduate Researcher",
        years: "September 2012 - February 2016",
        description: `
Working with Prof. [Kwan-Liu Ma](https://www.cs.ucdavis.edu/~ma/), in [VIDI](https://vidi.cs.ucdavis.edu/) (Visualization Interface and Design Innovation laboratory). Developed the following systems:
  * [Driving Behavior Visualization](https://www.youtube.com/watch?v=RmrlbKrVRAk)
  * [Interactive Visualization of Electric Vehicle Trajectory](https://www.youtube.com/watch?v=gxUq2Q7e7Tg)
  * [Incremental Storyline Layout Algorithms for Streaming Data](https://vimeo.com/136210767)
  * [Name disambiguation exploratory](https://www.youtube.com/watch?v=g1fcURD7Fh0)
  * Large-scale graph remote rendering system
        `,
      },
    ],
    skills: [
      {
        name: "Data Visualization",
        level: "90%",
      },
      {
        name: "ReactJs",
        level: "80%",
      },
      {
        name: "HTML5",
        level: "70%",
      },
      {
        name: "CSS",
        level: "60%",
      },
    ],
    publications: [
      {
        title: "A Study of using Motion for Comparative Visualization.",
        authors: "Chien-Hsin Hsueh, Jia-Kai Chou, Kwan-Liu Ma",
        venue: "IEEE Pacific Visualization Symposium, 2015",
      },
      {
        title:
          "Fostering Comparisons: Designing an Interactive Exhibit that Visualizes Marine Animal Behaviors.",
        authors:
          "Chien-Hsin Hsueh, Jacqueline Chu, Kwan-Liu Ma, Joyce Ma, Jennifer Frazier",
        venue: "IEEE Pacific Visualization Symposium, 2015",
      },
      {
        title:
          "An Efficient Framework for Generating Storyline Visualizations from Streaming Data.",
        authors: "Yuzuru Tanahashi, Chien-Hsin Hsueh, Kwan-Liu Ma",
        venue: "IEEE Transaction on Visualization and Computer Graphics, 2015",
      },
      {
        title:
          "Intrusive test automation with failed test case clustering. (Outstanding research award)",
        authors: "Chien-Hsin Hsueh, Yung-Pin Cheng, Wei-Cheng Pan",
        venue: "In Proceedings of APSEC‘2011, Ho Chi Minh, Vietnam",
      },
      {
        title: "xDIVA: automatic animation between debugging break points.",
        authors:
          "Yung-Pin Cheng, Han-Yi Tsai, Chih-Shun Wang, Chien-Hsin Hsueh",
        venue: "Proceedings of SOFTVIS'2010. pp. 221~222, Utah, USA",
      },
    ],
  },
  portfolio: {
    projects: [
      {
        title: "Graph.gl",
        image: "thumbs/graphgl.png",
        items: [
          {
            title: "Graph.gl",
            description:
              "is a React component for visualizing large graphs with several utility functions. With Graph.gl, users are enabled to build various type of graph/network applications with minimum efforts while having the capability to extend the existing styles and layouts.",
            image: "full/graphgl/1.png",
            type: "image",
          },
          {
            title: "Uber knowledge graph",
            description:
              "is aim to solve critical problems like fraud detection, criminal investigation, account abusing, long-hauling, driver onboarding, and even COVID-19 contact tracing.",
            image: "full/graphgl/2.gif",
            type: "image",
          },
        ],
      },
      {
        title:
          "Fostering Comparisons: Designing an Interactive Exhibit that Visualizes Marine Animal Behaviors",
        description:
          "Fostering Comparisons: Designing an Interactive Exhibit that Visualizes Marine Animal Behaviors",
        image: "thumbs/explo.png",
        url: "http://javid.me/explo",
        items: [
          {
            title:
              "Fostering Comparisons: Designing an Interactive Exhibit that Visualizes Marine Animal Behaviors",
            description: "",
            image: "full/explo/1.jpg",
            type: "video",
            src: "https://www.youtube.com/watch?v=psGvUuYxEbM",
          },
          {
            title: "Tangible User interface",
            description:
              " - a tangible multi-touch screen to be interacted with the users.",
            image: "full/explo/1.jpg",
            type: "image",
          },
          {
            title: "Foster visual comparison and conversation",
            description: "",
            image: "full/explo/2.jpg",
            type: "image",
          },
          {
            title: "Legends for different visual representations",
            description: "",
            image: "full/explo/3.jpg",
            type: "image",
          },
          {
            title: "Migration speed between species",
            description: "",
            image: "full/explo/4.jpg",
            type: "image",
          },
          {
            title: "Physical species cards",
            description:
              "are used to toggle different visual representations for each species and provide more context about the behavior.",
            image: "full/explo/5.jpg",
            type: "image",
          },
          {
            title: "Hot spot for the placement of the species cards",
            description: "",
            image: "full/explo/6.jpg",
            type: "image",
          },
        ],
      },
      {
        title: "Driving Behavior",
        image: "thumbs/motion.png",
        items: [
          {
            title: "A study of using motion for comparative visualization.",
            description:
              "We have designed visualizations to represent driver behaviors. A series of user studies have been conducted to verify if adding motion to the static visualization can help users make comparisons and separate drastically different behaviors. ",
            image: "full/motion/1.png",
            type: "video",
            src: "https://www.youtube.com/watch?v=RmrlbKrVRAk",
          },
          {
            title: "Overview of several drivers' driving behavior",
            description: "",
            image: "full/motion/1.png",
            type: "image",
          },
          {
            title: "Side-by-side visual comparison of two drivers",
            description: "",
            image: "full/motion/2.png",
            type: "image",
          },
          {
            title:
              "Side-by-side visual comparison of two drivers with different sorting order of the trips",
            description: "",
            image: "full/motion/3.png",
            type: "image",
          },
          {
            title: "Compare behavior by days",
            description: "",
            image: "full/motion/4.png",
            type: "image",
          },
        ],
      },
      {
        title:
          "A Visual Approach for Name Disambiguation in Coauthorship Networks",
        image: "thumbs/name.png",
        items: [
          {
            title:
              "A Visual Approach for Name Disambiguation in Coauthorship Networks",
            description: "",
            image: "full/name/1.png",
            type: "video",
            src: "https://www.youtube.com/watch?v=g1fcURD7Fh0",
          },
          {
            title: "UI for name disambiguation problem",
            description: "",
            image: "full/name/1.png",
            type: "image",
          },
        ],
      },
      {
        title: "Comparative driving behavior visualization",
        image: "thumbs/drivingBehavior.png",
        items: [
          {
            title: "Comparative driving behavior visualization.",
            description: "",
            image: "full/drivingBehavior/1.png",
            type: "video",
            src: "https://www.youtube.com/watch?v=gxUq2Q7e7Tg",
          },

          {
            title: "Driving behavior exploration UI.",
            description:
              "The goal of this project is to analyze driving behavior and visually compare them by using motion.",
            image: "full/drivingBehavior/1.png",
            type: "image",
          },
        ],
      },
      {
        title: "Marine animal migration visualization",
        image: "thumbs/topp.png",
        items: [
          {
            title:
              "Explore and marine animal behavior by integrating environmental data.",
            description: "",
            image: "full/topp/1.png",
            type: "image",
          },
        ],
      },
      {
        title: "Interactive visualization of Electric Vehicle Trajectory",
        image: "thumbs/trajectory.png",
        items: [
          {
            title: "Interactive visualization of Electric Vehicle Trajectory",
            description: "",
            image: "full/trajectory/1.png",
            type: "video",
            src: "https://www.youtube.com/watch?v=fUmWuGor-Lw",
          },
          {
            title: "Oveview of all the trajectories",
            description: "",
            image: "full/trajectory/1.png",
            type: "image",
          },
          {
            title: "Visualize speed and fuel consumption",
            description: "",
            image: "full/trajectory/2.png",
            type: "image",
          },
          {
            title:
              "Visualize multi-dimension trajectory data with stop detection",
            description: "",
            image: "full/trajectory/3.png",
            type: "image",
          },
        ],
      },
      {
        title: "Storyline visualization",
        image: "thumbs/storyline.png",
        items: [
          {
            title:
              "An Efficient Framework for Generating Storyline Visualizations from Streaming Data",
            description: "",
            image: "full/storyline/1.png",
            type: "video",
            src: "https://vimeo.com/136210767",
          },
          {
            title: "Storyline visualization by D3.js",
            description: "",
            image: "full/storyline/1.png",
            type: "image",
          },
          {
            title: "Storyline visualization for Minecraft survival mode",
            description: "",
            image: "full/storyline/2.png",
            type: "image",
          },
          {
            title: "Storyline visualization for Enron dataset to detect groups",
            description: "",
            image: "full/storyline/3.png",
            type: "image",
          },
        ],
      },
    ],
  },
};
